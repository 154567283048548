import { convertToDefaultDatetime } from "@/util"


export default class LogItemCheckListModel {
  constructor(item = {}) {
    this.ID = item.id
    this.comment = item.message
    this.author = {
      username: item.author
    }
    this.text = item.message
    this.createdDate = convertToDefaultDatetime(item.created_at)
    this.formattedDate = item.formatted_date
    this.status = item.status
    this.page = item.page
  }

  get fullName() {
    return {email: this.author}
  }
}
